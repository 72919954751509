import React from 'react';

import { graphql } from "gatsby";

import VowtchLayout from '../components/layouts/index';

import DashboardClassicView from '../components/dashboard/DashboardClassicView';

const Index = ({ history, data }) => {

  const allPagesJson = data.allPagesJson.edges;

  return (
    <VowtchLayout
      pages={allPagesJson}
    >
      <div>
        <DashboardClassicView
          pages={allPagesJson}
          title="Dashboard"
        />
      </div>
    </VowtchLayout>
  );
}

export const query = graphql`
  query PagesIndexQuery {
    allPagesJson {
      edges {
        node {
          card {
            grid {
              gridSpan
              gridSpanTreshold
            }
            headerWidgets {
              alternateHeaderWidget
              form {
                submitHandler {
                  action {
                    type
                  }
                }
              }
              grid {
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                field
                topic
              }
            }
            hasParent
            innerWidgets {
              alternateInnerWidget
              form {
                submitHandler {
                  action {
                    type
                  }
                }
              }
              grid {
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                card
                field
                topic
              }
              requiredInnerWidget
            }
            sections {
              avatar
              content
              field
              link
              title
            }
            sideWidgets {
              alternateSideWidget
              avatar
              avatarInformation {
                avatar
              }
              form
              grid {
                gridSpan
                gridSpanTreshold
              }
              inlineInformation {
                field
                menu {
                  title
                }
                topic
              }
              requiredSideWidget
              singleInformation
              topic
            }
            title
          }
          carousel {
            slides {
              image {
                src
              }
            }
            settings {
              autoplaySpeed
              dots
              effect
              image {
                pause
                play
              }
              speed
              static
              swipe
            }
          }
          cover {
            backgroundColor
            image {
              sizes {
                src
              }
              title
            }
          }
          datatransfomation {
            className
            defaultField
            extraMarkerField
            field
            markerField
          }
          form {
            actions {
              buttons {
                actionHandler {
                  href
                  payload
                  type
                }
                alternateButtonShown
                ascendant
                step
                title
              }
            }
            avatar
            cover {
              actionHandler {
                type
              }
              alternateCoverStep
              backgroundColor
              image {
                sizes {
                  src
                }
                title
              }
              justify
              step
              topic
            }            
            formFields {
              hidden
              rows {
                addonAfter {
                  avatar
                  button {
                    addonBefore {
                      avatar
                    }
                    label
                  }
                }
                addonBefore {
                  avatar
                }
                columns {
                  addonBefore {
                    avatar
                  }
                  button {
                    addonBefore {
                      avatar
                    }
                    label
                  }
                  buttonLabel
                  defaultValue
                  field
                  helper {
                    avatar
                    text {
                      text
                    }
                    title
                  }
                  hidden
                  image
                  label
                  select {
                    options {
                      title
                      value
                    }
                  }
                  tags {
                    closable
                    name
                  }
                  textarea
                }
                display
              }
              step
              title
            }
            grid {
              gridAddonAfterTreshold
              gridSpan
            }
            hasAlternateCover
            hasCover
            hasHeader
            menu {
              items {
                title
              }
              steps
            }
            submitHandler {
              action
              service
            }
            submitLabel
            text {
              extraMarkerFieldSlugText
            }
            title
          }
          isEditable
          slug
          table {
            columns {
              dataIndex
              image
              textSorter
              title
            }
            field
          }
          text {
            activityResultAlternateSlugText
            activityResultSlugText
            extraMarkerFieldSlugText
          }
          title
        }
      }
    }
  }
`

export default Index
